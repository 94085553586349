import angular = require('angular');
import * as _ from 'lodash';

export class JoinObjectFilter {
    static selector = 'joinObject';

    static factory() {
        let factoryFunction = ($filter: ng.IFilterService, generalUtils: any) => {
            // let angularDateFilter = $filter('date');
            return function (list: any, key: any) {
                if (!angular.isArray(list)) {
                    return list;
                }

                return _.join(_.map(list, (item => item[key] || 'Não informado')), ', ');
            };
        };

        factoryFunction.$inject = ['$filter', 'generalUtils'];

        return factoryFunction;
    }
}
