import angular = require('angular');

class SettingsController implements angular.IComponentController {
    loading?: boolean;
    list: any;

    constructor(
        private $urlRouter: any,
        private $scope: angular.IScope,
        private $rootScope: any,
        private generalUtils: any,
        private $uibModal: any,
        private ShareDataService: any
    ) {
        'ngInject';
    }

    $onInit() {
        this.loadList();

        this.$rootScope.$on('SETTINGS::REFRESH', () => {
            this.loadList();
        });
    }

    loadList() {
        this.loading = true;
        this.ShareDataService.getList()
            .then((data: any) => {
                this.list = data;
                this.loading = false;
                this.$scope.$apply();
            })
            .catch((e: any) => {
                this.loading = false;
                this.$scope.$apply();
            });
    }

    removeSettings(item: any) {
        this.ShareDataService.remove(item).then(() => {
            this.loadList();
        });
    }

    createSettingsModal() {
        this.$uibModal.open({
            component: 'createSettingsComponent',
            animation: true,
            backdrop: 'static',
            keyboard: false,
            size: 'md',
        }).result.then((data: any) => {
        }).catch((err: any) => {
        });
    }
}

export class SettingsComponent implements angular.IComponentOptions {
    static selector = 'settingsComponent';
    static controller = SettingsController;
    static template = require('./settings.html');
}
