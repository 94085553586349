class ContentHeaderController {
    hasMoreActions = this.$transclude.isSlotFilled('moreActions');
    hasSearchBox = this.$transclude.isSlotFilled('searchBox');
    hasExtra = this.$transclude.isSlotFilled('extra');

    constructor(
        private $transclude: any,
    ) {
        'ngInject';
    }
}

export class ContentHeaderComponent implements angular.IComponentOptions {
    static selector = 'contentHeader';
    static controller = ContentHeaderController;
    static transclude = {
        title: '?contentHeaderTitle',
        subtitle: '?contentHeaderSubtitle',
        moreActions: '?contentHeaderMoreActions',
        action: '?contentHeaderAction',
        searchBox: '?searchBox',
        extra: '?contentHeaderExtra'
    };
    static bindings = {
        breadcrumb: '<?'
    };
    static template = `
        <div class="header-wrapper" ng-style="{ 'padding-bottom': $ctrl.hasExtra ? 0 : '' }">
                <div class="title-wrapper">
                    <ng-transclude ng-transclude-slot="title"></ng-transclude>
                    <h3 ng-if="$ctrl.breadcrumb">
                        <ui-breadcrumbs displayname-property="data.displayName" abstract-proxy-property="data.proxy"></ui-breadcrumbs>
                    </h3>
                    <ng-transclude ng-transclude-slot="subtitle"></ng-transclude>
                </div>

                <span style="flex: 1 0 auto"></span>
                <ng-transclude class="actions-wrapper removeForPrint" ng-transclude-slot="action"></ng-transclude>


                <div class="more-actions-wrapper removeForPrint" ng-if="$ctrl.hasMoreActions">
                    <ng-transclude ng-transclude-slot="moreActions"></ng-transclude>
                </div>

                <div class="search-box-wrapper" ng-if="$ctrl.hasSearchBox">
                    <ng-transclude ng-transclude-slot="searchBox"></ng-transclude>
                </div>
            </div>
        <div class="extra-content">
                <ng-transclude ng-transclude-slot="extra"></ng-transclude>
            </div>
    `;
}
