import * as angular from 'angular';
import {WrapperComponent} from './wrapper/wrapper.component';
import {ShareDataService} from './shareData.service';
import {SearchComponent} from './search/search.component';
import {SettingsComponent} from './settings/settings.component';
import {CreateSettingsComponent} from  './settings/create/create.component';

// modules
export const moduleName = angular.module('application.sharedata', [])
    .component(WrapperComponent.selector, WrapperComponent)
    .component(SearchComponent.selector, SearchComponent)
    .component(SettingsComponent.selector, SettingsComponent)
    .component(CreateSettingsComponent.selector, CreateSettingsComponent)
    .service(ShareDataService.selector, ShareDataService)
    .name;
