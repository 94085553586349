import angular = require('angular');

class WrapperController implements angular.IComponentController {
    loading?: boolean;
    tabSelected: string;

    constructor(
        private $urlRouter: any,
        private $scope: angular.IScope,
        private $rootScope: any,
        private generalUtils: any
    ) {
        'ngInject';
    }

    $onInit() {
        this.tabSelected = 'search';

    }
}

export class WrapperComponent implements angular.IComponentOptions {
    static selector = 'wrapperComponent';
    static controller = WrapperController;
    static template = require('./wrapper.html');
}
