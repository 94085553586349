import angular = require('angular');

class CreateSettingsController implements angular.IComponentController {
    formComponent?: any;
    loading: boolean;
    resolve: any;
    close: ($event?: any) => void;
    dismiss: ($event?: any) => void;
    formData: {
        domain: '',
        apiKey: ''
    };

    constructor(
        private $urlRouter: any,
        private $scope: angular.IScope,
        private $rootScope: any,
        private generalUtils: any,
        private ShareDataService: any
    ) {
        'ngInject';
    }

    $onInit() {

    }

    save(form: any = this.formComponent, $event: any) {
        form.$setSubmitted();

        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        if (form.$invalid) {
            return this.generalUtils.onError('Ops!', 'Verifique se todos os campos foram preenchidos corretamente.', 'ok', () => {
                if ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                }
            });
        }

        this.loading = true;
        this.ShareDataService.save(this.formData)
            .then((data: any) => {
                delete this.loading;
                this.close();
                this.$rootScope.$broadcast('SETTINGS::REFRESH');
                this.generalUtils.onSuccess('Operação concluída!', 'Configuração cadastrada com sucesso.', 'ok', '', () => {
                });
            })
            .catch((err: any) => {
                delete this.loading;
            });
    }
}

export class CreateSettingsComponent implements angular.IComponentOptions {
    static selector = 'createSettingsComponent';
    static bindings = {
        resolve: '<',
        close: '&',
        dismiss: '&'
    };
    static controller = CreateSettingsController;
    static template = require('./create.html');
}
