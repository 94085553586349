export class FormatDocFilter {
    static selector = 'formatDoc';

    static factory() {
        let factoryFunction = ($filter: ng.IFilterService, generalUtils: any) => {
            return (doc: string) => {
                if (!doc) {
                    return;
                }
                return generalUtils.formatCPForCNPJ(doc);
            };
        };

        factoryFunction.$inject = ['$filter', 'generalUtils'];

        return factoryFunction;
    }
}
