class ContentController {
    constructor(
        private $transclude: any,
    ) {
        'ngInject';
    }
}

export class ContentComponent implements angular.IComponentOptions {
    static selector = 'content';
    static controller = ContentController;
    static transclude = {
        header: '?contentHeader',
        body: 'contentBody',
    };
    static template = `
        <div class="content-wrapper">
            <ng-transclude ng-transclude-slot="header"></ng-transclude>
            <div class="body-container">
                <ng-transclude class="body-wrapper" ng-transclude-slot="body"></ng-transclude>
            </div>
        </div>
    `;
}
