export class BootstrapController implements angular.IComponentController {
    static selector = 'BootstrapController';
    title = 'MC Forms';

    constructor(
        private $rootScope: any,
        private $scope: any
    ) {
        'ngInject';
    }
}
