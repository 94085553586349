export class ShareDataService {
    static selector = 'ShareDataService';

    constructor(
        private localStorageService: any,
        private $rootScope: any,
        private Restangular: any
    ) {
        'ngInject';
        return this;
    }

    getList = () => {
        return new Promise((resolve, reject) => {
            this.Restangular.all('settings').customGET()
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        });
    }

    save = (formData: any) => {
        return new Promise(((resolve, reject) => {
            this.Restangular.all('settings').post(formData)
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        }));
    }

    remove = (settings: any) => {
        return new Promise(((resolve, reject) => {
            this.Restangular.one('settings', settings._id).remove()
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        }));
    }

    search = (searchData: any) => {
        return new Promise(((resolve, reject) => {
            this.Restangular.all('search').customGET('', {search: searchData})
                .then((data: any) => resolve(data ? data.plain() : null), reject);
        }));
    }

}
