export class Environment {
    static selector = 'environment';

    static get() {
        return {
            apiUrl: `https://sharedata.api.apps.magnetcustomer.com`,
            // apiUrl: `http://localhost:7001`
        };
    }
}
