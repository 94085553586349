import angular = require('angular');

class SearchController implements angular.IComponentController {
    formComponent?: any;
    loading?: boolean;
    searchData: any;
    searchList: any;

    constructor(
        private $urlRouter: any,
        private $scope: angular.IScope,
        private $rootScope: any,
        private generalUtils: any,
        private ShareDataService: any
    ) {
        'ngInject';
    }

    $onInit() {

    }

    clear() {
        this.searchData = '';
    }

    search(form: any = this.formComponent, $event: any) {
        form.$setSubmitted();

        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }

        if (form.$invalid) {
            return this.generalUtils.onError('Ops!', 'Verifique se todos os campos foram preenchidos corretamente.', 'ok', () => {
                if ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();
                }
            });
        }

        this.loading = true;
        this.generalUtils.startLoader();
        this.ShareDataService.search(this.searchData)
            .then((data: any) => {
                this.loading = false;
                this.generalUtils.hideLoader();
                this.searchList = data;
                try {
                    this.$scope.$apply();
                } catch (e) {

                }
            })
            .catch((err: any) => {
                this.generalUtils.hideLoader();
                this.loading = false;
                try {
                    this.$scope.$apply();
                } catch (e) {

                }
            });
    }

    viewCustomer(customer: any, item: any) {
        window.open(`https://${item.domain}/#/customers/view/${customer._id}`, '_blank');
    }
}

export class SearchComponent implements angular.IComponentOptions {
    static selector = 'searchComponent';
    static controller = SearchController;
    static template = require('./search.html');
}
