import * as angular from 'angular';
import * as _ from 'lodash';

export class PhoneFilter {
    static selector = 'phone';

    static factory() {
        let factoryFunction = ($filter: ng.IFilterService, generalUtils: any) => {
            // let angularDateFilter = $filter('date');
            return function (input: any, resultExpected: any) {
                let phone: any = '';

                if (_.isEmpty(input)) {
                    return '';
                }

                if (angular.isArray(input) && input.length > 0) {
                    phone = input.find(({typePhone}) => typePhone.toLowerCase() === 'whatsapp');
                    if (!phone) {
                        phone = input.find(({typePhone}) => typePhone.toLowerCase() === 'cellphone');
                    }
                    if (!phone) {
                        phone = input.find(({typePhone}) => typePhone.toLowerCase() === 'home');
                    }
                    if (!phone) {
                        phone = input.find(({typePhone}) => typePhone.toLowerCase() === 'business');
                    }
                    if (!phone) {
                        phone = input.find(({typePhone}) => typePhone.toLowerCase() === 'application');
                    }

                } else if (angular.isObject(input)) {
                    phone = input;

                } else if (angular.isString(input) || angular.isNumber(input)) {
                    return generalUtils.formatPhone(input);
                }

                if (!phone) {
                    return '';
                }

                switch (resultExpected) {
                    case 'number':
                        return phone.number ? generalUtils.formatPhone(phone.number) : 'Não cadastrado';
                    case 'type':
                        return phone.typePhone;
                    default:
                        return {
                            type: phone.typePhone, number: generalUtils.formatPhone(phone.number)
                        };
                }
            };
        };

        factoryFunction.$inject = ['$filter', 'generalUtils'];

        return factoryFunction;
    }
}
