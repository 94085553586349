import * as moment from 'moment';

export const config = (
    RestangularProvider: any,
    $mdDateLocaleProvider: any,
    localStorageServiceProvider: any,
    environment: any
) => {
    'ngInject';

    localStorageServiceProvider.setPrefix('MC_SHAREDATA');

    RestangularProvider
        .setBaseUrl(environment.get().apiUrl)
        .setRestangularFields({id: '_id'})
        .setDefaultHttpFields({'timeout': 600000});

    $mdDateLocaleProvider.formatDate = function (date: any) {
        return moment(date).format('DD/MM/YYYY');
    };
};
