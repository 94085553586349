import {AppComponent} from './components/app/app.component';
import * as _ from 'lodash';

async function getParameterByName(name: any, url: any) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const routing = (
    $stateProvider: angular.ui.IStateProvider,
    $urlRouterProvider: angular.ui.IUrlRouterProvider
) => {
    'ngInject';

    $stateProvider.state('app', {
        url: '/?token',
        component: AppComponent.selector,
        resolve: {
            Token: ['$stateParams', 'Restangular', async ($stateParams: any, Restangular: any) => {
                let authorization = {
                    'authorization': await getParameterByName('token', '')
                };
                Restangular.setDefaultHeaders(authorization);
                console.log(authorization);
                return authorization;
            }]
        }
    });

    $urlRouterProvider.otherwise('/');
};
